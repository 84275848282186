<template>
  <div></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "public_se_finance",
  setup() {},
});
</script>
